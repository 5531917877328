// src/App.js
import React, { useState, useEffect } from 'react';
import { Button, TextField, Alert, Box, List, ListItem, ListItemText, Modal } from '@mui/material';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_BACKEND_ADDRESS;

export default function App() {
  const [username, setUsername] = useState('');
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refId, setRefId] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [showMessages, setShowMessages] = useState(true);

  useEffect(() => {
    const initTelegram = async () => {
      const WebApp = window.Telegram.WebApp;
      if (WebApp) {
        const userId = WebApp.initDataUnsafe.user?.id || 0;
        const username = WebApp.initDataUnsafe.user?.username || "Unknown User";
        setChatId(userId);
        setUsername(username);
        
        const params = new URLSearchParams(window.location.search);
        const refIdParam = params.get('tgWebAppStartParam') || 0;
        setRefId(refIdParam);
  
        fetchMessages(userId);
        //alert(`Ref ID: ${refIdParam}`)
      } else {
        alert('Telegram Web App is not available');
      }
    };
    initTelegram();
  }, []);

  const handleSubmit = async () => {
    await axios.post(`${apiBaseUrl}/ask`, {
      chat_id: parseInt(refId),
      username: username,
      question: question,
    });
    setQuestion('');
    setShowMessages(false); 
  };

  const fetchMessages = async (chat_id) => {
    const response = await axios.get(`${apiBaseUrl}/messages?chat_id=${chat_id}`);
    setMessages(response.data);
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  const handleSkip = () => {
    setQuestion('');
    setShowMessages(false);
  };

  return (
    <Box p={2}>
      <p>{username}</p>
      {showMessages && (
      <Box mb={2}>
        <TextField
          label="Your Question"
          variant="outlined"
          fullWidth
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          inputProps={{ maxLength: 250 }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!question.trim()}>
          Send
        </Button>
        <Button variant="outlined" onClick={handleSkip}>
          Skip
        </Button>
      </Box>
      )}
      <b>Inbox</b>
      
        <List>
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <ListItem divider={true} button key={index} onClick={() => handleMessageClick(msg)}>
                <ListItemText primary={msg.shortText} />
              </ListItem>
            ))
          ) : (

            Array.from({ length: 5 }).map((_, index) => (
              <ListItem key={index}>
                <ListItemText primary={`Placeholder Message ${index + 1}`} />
              </ListItem>
            ))
          )}
        </List>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box p={3} bgcolor="background.paper">
          <Alert>{selectedMessage?.fullText}</Alert>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
}
